import './App.scss';
import { RouterProvider } from 'react-router-dom';
import navigator from './navigators';

function App() {
  return (
    <RouterProvider router={ navigator }/>
  )
}

export default App;
